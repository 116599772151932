/* fetch('/grandangolo.json')
.then((content) => content.json())
.then((data) => {
    data = {'url': 'https://www.byoblu.com/2024/06/28/assange-linquisizione-dellimpero-condanna-il-giornalismo-eretico-grandangolo-pangea/', 'title': 'ASSANGE: L’INQUISIZIONE DELL’IMPERO CONDANNA IL GIORNALISMO ERETICO', 'image': 'https://www.byoblu.com/wp-content/uploads/2024/06/COPERTINA-2-534x300.jpg'};
    [].forEach.call(document.getElementsByClassName('grandangolo-title'), element => {
        element.textContent = data.title;
    });

    [].forEach.call(document.getElementsByClassName('grandangolo-image'), element => {
        element.src = data.image;
    });

    [].forEach.call(document.getElementsByClassName('grandangolo-url'), element => {
        element.onclick = null;
        element.addEventListener('click', () => onSquareClick(data.url));
    });
// })  */